import { Analytics } from "@services/analytics";
import { store } from "@services/redux";
import axios from "axios";

const own_axios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

own_axios.interceptors.request.use((config: any) => {
  const needsAuthHeader = config.baseURL?.indexOf(process.env.REACT_APP_API_BASE_URL) === 0;

  if (!needsAuthHeader) {
    return config;
  }

  const authorizationHeader = {
    transactionId: store.getState().transaction.data.id || "",
    token: store.getState().transaction.data.token || "",
  };

  return {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...config.headers.common,
        Authorization: JSON.stringify(authorizationHeader),
      },
    },
  };
});

own_axios.interceptors.response.use(
  (response) => {
    Analytics.restoreErrorInfo();
    return response;
  },
  (error) => {
    if (error.response?.status) {
      Analytics.setErrorInfo(error.config.url, error.response?.status, error.message);
    }
    return Promise.reject(error);
  }
);

export const FES_API = own_axios;
