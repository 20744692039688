import { LoadingPage } from "../pages";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { importRetry } from "@common/import-retry";
import ErrorBasePage from "@product-pages/pages/ErrorBasePage";

const LicensesPage = React.lazy(() => importRetry(() => import("../pages/LicensesPage")));

export const ProductIndependentPageRoutes = () => (
  <Routes>
    <Route path="loading" element={<LoadingPage />} />
    <Route path="licenses" element={<LicensesPage />} />
    <Route path="error" element={<ErrorBasePage />} />
    <Route path="*" element={<Navigate to="/error" replace={true} />} />
  </Routes>
);
