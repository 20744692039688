import { Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";

export const ErrorCustomerService = () => {
  const { t } = useTranslation();
  return (
    <CommonProductPage>
      <SectionHeading center level="3" smallSpacing={true} className="u-mb-large">
        {t("error:errorCustomerService:title")}
      </SectionHeading>
      <Paragraph className="u-text-center">{t("error:errorCustomerService:text")}</Paragraph>
    </CommonProductPage>
  );
};
