import { FES_API } from "@apis/common";

export const REALM_NAME = "vwfs-ds-osfnecst";

export const userInfo = async (token: string) => {
  const domain = process.env.REACT_APP_IDP_URL;
  const url = `${domain}/realms/${REALM_NAME}/protocol/openid-connect/userinfo`;
  return FES_API.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const logOut = async ({ token, clientId, clientSecret, refreshToken }: any) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const data = {
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: refreshToken,
  };

  const domain = process.env.REACT_APP_IDP_URL;
  const url = `${domain}/realms/${REALM_NAME}/protocol/openid-connect/logout`;

  return FES_API.post(url, new URLSearchParams(data), { headers });
};
