import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { CHImodal } from "./CHImodal";
import { CHIsection } from "./CHIsection";
import { JourneyRoutes } from "@common/models/enums";

export type CHIProps = {};

export const CHIpaths = [JourneyRoutes.SIGNATURE_SUCCESS];

const CHI: FunctionComponent<CHIProps> = (props) => {
  const [CHImodalVisible, setCHImodalVisible] = React.useState(false);

  const onConfirm = () => {
    setCHImodalVisible(true);
  };

  const timerCHImodalPop = 2000;

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const pageShownTimer = setTimeout(() => {
      setCHImodalVisible(true);
    }, timerCHImodalPop);
    return () => {
      clearTimeout(pageShownTimer);
    };
  }, []);

  return (
    <>
      <CHIsection onConfirm={onConfirm} />
      <CHImodal
        visible={CHImodalVisible}
        onCancel={() => {
          setCHImodalVisible(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect<{}, {}, CHIProps>(mapStateToProps)(CHI);
