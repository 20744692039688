export enum StandalonePagePath {
  error = "error",
  errorOption = "error-option",
  errorCustomerService = "error-customer-service",
  expiredLink = "expired-link",
  loading = "loading",
  errorDocument = "document-error",
  documentUploadSuccess = "document-upload-success",
  legitimationMismatch = "legitimation-mismatch",
  legitimationTemporaryError = "temporary-error",
  legitimationSignatureFailed = "signature-failed",
}
