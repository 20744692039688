import { useFootnotes } from "@features/footnotes";
import React, { useEffect } from "react";

interface Props {
  text: string;
  children?: React.ReactNode;
}

export const Footnote: React.FC<Props> = ({ text, children }) => {
  const { footnotes, addFootnoteOccurrence, removeFootnoteOccurrence } = useFootnotes();

  useEffect(() => {
    addFootnoteOccurrence(text);
    return () => {
      removeFootnoteOccurrence(text);
    };
  }, [addFootnoteOccurrence, removeFootnoteOccurrence, text]);

  const footnote = footnotes[text];

  if (!footnote) {
    return <span className="u-ws-nowrap">{children}</span>;
  }

  return (
    <span className="u-ws-nowrap">
      {children}
      <sup className="c-hint">
        <a href={`#footnote-${footnote.index}`}>{footnote.index}</a>
      </sup>
    </span>
  );
};
