import { useEffect } from "react";
import { isObject, useFormikContext } from "formik";
import { getTopmostElement, scrollToElementIfRequired } from "@common/scroll";

const flatten = (obj: any, prefix = ""): string[] =>
  Object.keys(obj).reduce((res, el) => {
    if (obj[el] === undefined) {
      return res;
    } else if (Array.isArray(obj[el]) || isObject(obj[el])) {
      return [...res, ...flatten(obj[el], prefix + el + ".")];
    } else {
      return [...res, prefix + el];
    }
  }, [] as string[]);

export const ScrollToFirstError = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    if (!isSubmitting || isValidating) {
      return;
    }

    const elementNames = flatten(errors);
    if (elementNames.length > 0) {
      const topmostElement = getTopmostElement(elementNames);
      scrollToElementIfRequired(topmostElement);
    }
  }, [isSubmitting, isValidating, errors]);

  return null;
};
