import { SectionHeading, Spinner } from "@vwfs-bronson/bronson-react";

interface LoadingProps {
  showSpinner?: boolean;
}

export const Loading = ({ showSpinner = true }: LoadingProps) => (
  <>
    <SectionHeading level="3" smallSpacing={true}>
      Bitte warten, Daten werden geladen...
    </SectionHeading>
    {showSpinner && <Spinner center={true} />}
  </>
);
