interface Props {
  href: string;
  target?: string;
  children?: React.ReactNode;
}

export const ButtonLink: React.FC<Props> = ({ href, target, children }) => (
  <a href={href} target={!!target ? target : "_self"} className="c-btn c-btn--link c-btn--small">
    <i className="c-btn__icon c-icon c-icon--[semantic-forward]" />
    <span className="c-btn__text">{children}</span>
  </a>
);
