export const SET_SHOW_CONTINUELATER = "custom//SET_SHOW_CONTINUELATER";
export const SET_HASDACERROR = "custom//SET_HASDACERROR";
export const SET_LEGITIMATION_URL = "custom//SET_LEGITIMATION_URL";
export const SET_SHOW_EMAIL_VALIDATION = "custom//SET_SHOW_EMAIL_VALIDATION";
export const SET_IS_EMAIL_VALIDATED = "custom//SET_IS_EMAIL_VALIDATED";
export const SET_IS_TLS_SUPPORTED = "custom//SET_IS_TLS_SUPPORTED";

interface SetShowContinueLater {
  type: typeof SET_SHOW_CONTINUELATER;
  payload: any;
}

interface SetHasDacError {
  type: typeof SET_HASDACERROR;
  payload: any;
}

interface setLegitimationUrl {
  type: typeof SET_LEGITIMATION_URL;
  payload: any;
}
interface setShowEmailvalidation {
  type: typeof SET_SHOW_EMAIL_VALIDATION;
  payload: any;
}
interface setIsEmailValidated {
  type: typeof SET_IS_EMAIL_VALIDATED;
  payload: any;
}
interface setIsTlsSupported {
  type: typeof SET_IS_TLS_SUPPORTED;
  payload: any;
}

export type CustomActions = SetShowContinueLater | SetHasDacError | setLegitimationUrl | setShowEmailvalidation | setIsEmailValidated | setIsTlsSupported;
