import { Stage } from "./types";

export const getEnvVarString = (varName: string) => {
  const stringValue = process.env[varName];
  if (!stringValue) {
    throw new Error(`Environment variable "${varName}" is not defined`);
  }
  return stringValue;
};

export const getEnvVarInt = (varName: string) => {
  const stringValue = getEnvVarString(varName);
  const intValue = parseInt(stringValue, 10);
  if (isNaN(intValue)) {
    throw new Error(`Value of environment variable "${varName}" is not a valid integer`);
  }
  return intValue;
};

export const ENV = {
  stage: getEnvVarString("REACT_APP_STAGE").toLowerCase() as Stage,
};

export const isTestStage = () => process.env.NODE_ENV !== "production" || ["dev", "int", "cons"].includes(ENV.stage);
