import { FloatingBar, FoldOut, ProductInfoSummary } from "@vwfs-bronson/bronson-react";
import { useRef } from "react";

interface Props {
  infoLabel: string;
  infoValue: string;
  title: string;
  subtitle: string;
  imgSrc?: string;
  children?: React.ReactNode;
}

export const ShoppingCart: React.FC<Props> = ({ infoLabel, infoValue, title, subtitle, imgSrc, children }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div ref={scrollRef}></div>
      <FoldOut productInfoSummary={<ProductInfoSummary infoLabel={infoLabel} infoValue={infoValue} title={title} subtitle={subtitle} imgSrc={imgSrc} />}>{children}</FoldOut>
      <FloatingBar target={scrollRef}>
        <ProductInfoSummary infoLabel={infoLabel} infoValue={infoValue} title={title} subtitle={subtitle} compact={true} />
      </FloatingBar>
    </>
  );
};
