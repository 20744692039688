import { Analytics } from "@services/analytics";

export const pageName = "Document upload";

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
  [Analytics.keys.viewChange()]: "API error",
};

export const trackPage = () => {
  const specificTrackingData = {
    [Analytics.keys.eventType(0)]: "pageView",
    [Analytics.keys.eventAction(0)]: "Success",
  };

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  });
};
