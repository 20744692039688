import { CommonPage, Loading } from "@components/common";

interface LoadingPageProps {
  showSpinner?: boolean;
}

export const LoadingPage = ({ showSpinner = true }: LoadingPageProps) => (
  <CommonPage>
    <Loading showSpinner={showSpinner} />
  </CommonPage>
);
