import React, { FunctionComponent, useState } from "react";
import { ContentSection, Modal, Paragraph, Spinner } from "@vwfs-bronson/bronson-react";
import { useTranslation } from "react-i18next";
import { CHIsection } from "./CHIsection";
import { getQuestionnaireUrl } from "@services/api/custom-happiness-index";
import { StandalonePagePath } from "@product-pages/pages";
import { useNavigate } from "react-router-dom";

interface CHImodalProps {
  visible: boolean;
  onCancel: any;
  onConfirm: any;
}

export interface Props {
  name?: any;
  label?: any;
  readOnly?: any;
  isNumber?: any;
  setFieldValue?: any;
  onChange?: any;
  onBlur?: any;
  value?: any;
}

export const CHImodal: FunctionComponent<CHImodalProps> = (props) => {
  const { t } = useTranslation();
  const { visible, onCancel } = props;
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      const data = await getQuestionnaireUrl();
      if (data?.custom?.questionnaireUrl) {
        window.open(data?.custom?.questionnaireUrl, "_blank");
      }
    } catch {
      navigate(StandalonePagePath.error, { replace: true });
    } finally {
      onCancel();
      setIsLoading(false);
    }
  };
  const buildCHILink = (href: string) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {t("chi:legal:hier")}
    </a>
  );

  return (
    <>
      {isLoading && <Spinner fullPage />}
      <Modal id="chiModal" large shown={visible} onClose={() => onCancel()}>
        <CHIsection onConfirm={onConfirm} modal />
        <ContentSection pageWrap className="u-pv-small">
          <Paragraph className="c-footnotes">
            {t("chi:modal:legal:text1")}
            {buildCHILink(t("chi:modal:legal:link1"))}
            {t("chi:modal:legal:text2")}
            {buildCHILink(t("chi:modal:legal:link2"))}
            {t("chi:modal:legal:text3")}
            {buildCHILink(t("chi:modal:legal:link3"))}.
          </Paragraph>
        </ContentSection>
      </Modal>
    </>
  );
};
