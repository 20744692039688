export const scrollToElementIfRequired = (element?: HTMLElement) => {
  if (!element) {
    return;
  }

  const offsetTop = getElementTop(element) - window.innerHeight / 4;
  if (offsetTop < window.scrollY) {
    window.scrollTo(0, offsetTop);
  }

  element.focus();
};

export const scrollToElement = (element?: HTMLElement) => {
  if (!element) {
    return;
  }

  const offsetTop = getElementTop(element) - window.innerHeight / 4;
  if (offsetTop) {
    window.scrollTo(0, offsetTop);
  }

  element.focus();
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const getElementTop = (element?: HTMLElement): number => {
  if (!element) {
    return Number.MAX_VALUE;
  }

  let elementToUse = element;
  let rect = elementToUse.getBoundingClientRect();
  if ((rect.height === 0 || rect.width === 0) && elementToUse.parentElement !== null) {
    // Special handling for hidden input fields: use rect of parent element
    elementToUse = elementToUse.parentElement;
  }

  return elementToUse.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
};

export const getTopmostElement = (elementNames: string[]): HTMLElement | undefined => {
  return elementNames.reduce<HTMLElement | undefined>((topmostElement, currentElementName) => {
    const currentElement = document.getElementsByName(currentElementName)[0];
    const currentTop = getElementTop(currentElement);
    return currentTop < getElementTop(topmostElement) ? currentElement : topmostElement;
  }, undefined);
};
