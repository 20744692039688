/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
import classNames from "classnames";
import { PropsWithChildren, useEffect, useState } from "react";
import "./FormSection.css";

interface Props {
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  noTruncate?: boolean;
  success?: boolean;
  expanded: boolean;
  titleLink?: string;
  onSectionHeaderClick?: (currentlyExpanded: boolean) => void;
}

export const FormSection = ({ title, subtitle, disabled, noTruncate, success, expanded, titleLink, onSectionHeaderClick, children }: PropsWithChildren<Props>) => {
  const [wasNeverExpanded, setWasNeverExpanded] = useState(!expanded);

  useEffect(() => {
    setWasNeverExpanded(wasNeverExpanded && !expanded);
  }, [wasNeverExpanded, expanded]);

  return (
    <section
      className={classNames("c-form-section js-form-section js-is-ready", {
        "c-form-section--success": success,
        "is-open": expanded,
      })}
      aria-disabled={disabled}
    >
      <button
        type="button"
        className={classNames("c-form-section__header js-form-section__header", {
          "c-form-section__header--no-truncate": noTruncate, //
          "header-button-disabled": expanded,
        })}
        aria-expanded={expanded}
        onClick={() => onSectionHeaderClick?.(expanded)}
      >
        <span className="c-form-section__header-content">
          <span className="c-form-section__title js-form-section__trigger">
            <span className="c-form-section__title-text" role="heading">
              {title}
            </span>
            {titleLink && (
              <span className="c-btn c-form-section__title-link c-btn--link c-btn--small">
                <i className="c-btn__icon c-icon c-icon--[pen]" aria-hidden="true" role="img"></i>
                <span className="c-btn__text">{titleLink}</span>
              </span>
            )}
          </span>
          {subtitle && <span className="c-form-section__subtitle">{subtitle}</span>}
        </span>
        <span className="c-form-section__header-icon" />
      </button>

      <div className={classNames("c-form-section__panel js-form-section__panel", { "is-open": expanded, "was-never-expanded": wasNeverExpanded })} aria-hidden={!expanded}>
        <div className="c-form-section__content">{children}</div>
      </div>
    </section>
  );
};
