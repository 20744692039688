import { UPLOAD_DOCUMENT, DELETE_DOCUMENT, READ_DOCUMENT, GET_DOCUMENTS, FormActions, SET_FILES } from "./actions/documentUpload.actions";
import { DocumentUploadState } from "../models/documentUploadState";

export function setFiles(data: any): FormActions {
  return {
    type: SET_FILES,
    payload: data,
  };
}

export function getFiles(store: any) {
  return store.document.data.files;
}

export function uploadDocument(data: any): FormActions {
  return {
    type: UPLOAD_DOCUMENT,
    payload: data,
  };
}

export function deleteDocument(data: any): FormActions {
  return {
    type: DELETE_DOCUMENT,
    payload: data,
  };
}
export function readDocument(data: any): FormActions {
  return {
    type: READ_DOCUMENT,
    payload: data,
  };
}

export function getDocuments(data: any): FormActions {
  return {
    type: GET_DOCUMENTS,
    payload: data,
  };
}
const initialState: DocumentUploadState = {
  data: {
    requiredDocuments: {
      documents: [],
    },
    files: [],
    uploadedDocuments: [],
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_FILES:
      return {
        ...state,
        data: {
          ...state.data,
          files: action.payload,
        },
      };
    case UPLOAD_DOCUMENT:
      return {
        ...state,
        data: {
          ...state.data,
          uploadedDocuments: [...state.data.uploadedDocuments, action.payload.data],
        },
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        data: {
          ...state.data,
          uploadedDocuments: state.data.uploadedDocuments.filter((file: any) => {
            return file.id !== action.payload;
          }),
        },
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          requiredDocuments: action.payload,
        },
      };

    default:
      return state;
  }
}
