import { Analytics } from "@services/analytics";

export const pageName = "Error page";

const defaultTrackingData = {
  [Analytics.keys.pageName()]: pageName,
};

export const trackPage = () => {
  const specificTrackingData = {
    [Analytics.keys.viewChange()]: Analytics.getFromDatalayer(Analytics.keys.pageName()),
    [Analytics.keys.eventType(0)]: "pageView",
    [Analytics.keys.eventAction(0)]: "Success",
    [Analytics.keys.eventType(1)]: Analytics.getFromCurrentData(Analytics.keys.errorCode())?.toString() === "500" ? "API Error" : "Error",
    [Analytics.keys.eventAction(1)]: "Detected",
  };

  Analytics.trackPage({
    ...defaultTrackingData,
    ...specificTrackingData,
  });
};
