import { connect } from "react-redux";
import ErrorBasePage from "./ErrorBasePage";
import { setShowContinueLater } from "@services/redux/features/custom.redux";
export interface ErrorPageLocationState {
  errorMessage?: string;
}

export interface ErrorOptionPageProps {}

export interface ErrorOptionReduxPageProps {
  setShowContinueLater: Function;
}

const ErrorOptionPage = ({ setShowContinueLater }: ErrorOptionReduxPageProps) => {
  const onClick = () => {
    setShowContinueLater(true);
  };
  return <ErrorBasePage onClick={onClick} />;
};

const mapStateToProps = (state: any) => {
  return {};
};
export default connect<{}, {}, ErrorPageLocationState>(mapStateToProps, { setShowContinueLater })(ErrorOptionPage);
