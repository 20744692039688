import Keycloak from "keycloak-js";

const scope = "profile email phone address birthdate firstName lastName";

const url = process.env.REACT_APP_IDP_URL || "";

const clientId = "custPortalOsfnecstAutoVwfs";

const keycloakConfig = {
  verimi: {
    realm: "vwfs-ds-osfnecst",
    clientId,
    url,
    scope: "profile email phone address birthdate firstName lastName",
  },
  test: {
    url: "http://localhost:8080/auth/",
    realm: "demo",
    clientId: "vw",
    scope,
  },
};

const keycloakClient = {
  verimi: {
    name: "verimi",
    idpHint: "verimiosfnecstvwfs",
    kc: Keycloak(keycloakConfig.verimi),
  },
};

export default keycloakClient;
