import * as React from "react";
import { ToastNotification, ToastNotifications } from "@vwfs-bronson/bronson-react";
import classNames from "classnames";
import LogoDesktop from "./images/vwfs-default-logo_desktop.svg";
import LogoMobile from "./images/vwfs-default-logo_mobile.svg";
import "./PageHeader.css";
import { getKeycloakInitialized, getKeycloakIsLogged } from "@services/redux/features/keycloak.redux";
import { connect } from "react-redux";
import { logOut } from "@services/api/login";
import { useTranslation } from "react-i18next";
import useKeycloakConditonal from "@common/hooks/useKeycloakConditional";

export interface PageHeaderProps {
  documentTitle?: string;
  showHeroTeaser?: boolean;
  heroTeaserImage?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

export interface PageHeaderReduxProps extends PageHeaderProps {
  isLoggedIn: boolean;
  keycloakInitialized: boolean;
}

const PageHeader = (props: PageHeaderReduxProps) => {
  const { t } = useTranslation();
  const [logoutSuccessful, setLogoutSuccessful] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const { documentTitle, isLoggedIn, keycloakInitialized, showHeroTeaser, title, heroTeaserImage, subTitle } = props;
  const { keycloak } = useKeycloakConditonal(keycloakInitialized);

  const docTitle = documentTitle ? documentTitle : title;
  const headerTitle = !showHeroTeaser ? title : undefined;
  const isHeaderStatic = !showHeroTeaser;

  React.useEffect(() => {
    document.title = `${docTitle ? `${docTitle} - ` : ""}Volkswagen Bank GmbH`;
  }, [docTitle]);

  let removeNotification: any;
  const handleShowNotification = (value: boolean) => {
    setLogoutSuccessful(value);
    setShowNotification(true);
    removeNotification = setTimeout(() => setShowNotification(false), 5000);
  };

  const setLoggedOut = async (e: any) => {
    e.preventDefault();

    if (keycloak) {
      try {
        await logOut({
          token: keycloak.token,
          clientId: keycloak.clientId,
          clientSecret: keycloak.clientSecret,
          refreshToken: keycloak.refreshToken,
        });
        keycloak?.clearToken();
        handleShowNotification(true);
      } catch (error) {
        handleShowNotification(false);
      }
    }
  };

  return (
    <>
      {showNotification && (
        <ToastNotifications>
          <ToastNotification
            onClose={() => {
              setShowNotification(false);
              clearTimeout(removeNotification);
            }}
            {...(logoutSuccessful ? { success: true } : { error: true })}
          >
            {t("login:logoutSuccess")}
          </ToastNotification>
        </ToastNotifications>
      )}
      <div className="b1-page-header">
        <header className="c-header__wrapper">
          <div
            className={classNames("c-header", {
              "c-header--static": isHeaderStatic,
            })}
          >
            <div className="c-header__content-wrapper">
              <div className="c-header__logo">
                <a className="c-logo c-logo--has-image" href="https://www.vwfs.de/">
                  <picture>
                    <source srcSet={LogoMobile} media="(max-width: 720px)" />
                    <img src={LogoDesktop} className="c-logo__image" alt="Logo" />
                  </picture>
                </a>
              </div>

              {(title || isLoggedIn) && (
                <div className="c-context-bar">
                  <div className="c-context-bar__panel" aria-hidden="true">
                    <nav className="c-context-bar__navs" aria-label="primary">
                      {title && (
                        <ul className="c-context-bar__nav">
                          <li>
                            <span className="c-context-bar__title">{headerTitle}</span>
                          </li>
                        </ul>
                      )}
                      {isLoggedIn && (
                        <ul className="c-context-bar__nav">
                          <li>
                            <a className="c-context-bar__link" href="#/" onClick={setLoggedOut} rel="noopener noreferrer">
                              <span>{t("login:logout")}</span>
                            </a>
                          </li>
                        </ul>
                      )}
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>

        <div className="o-page-wrap b1-header-title">
          <h1>{headerTitle}</h1>
        </div>

        {showHeroTeaser && (
          <div className="c-hero-teaser c-hero-teaser--auto">
            {heroTeaserImage ? <img className="c-hero-teaser__image" src={heroTeaserImage} alt={headerTitle?.toString()} /> : <div className="c-hero-teaser__image" />}

            <div className="c-hero-teaser__content">
              <div className="c-hero-teaser__content-inner">
                <header className="c-hero-teaser__heading">
                  <div className="c-hero-teaser__title">
                    <h1>{title && <span className="c-hero-teaser__title-row">{title}</span>}</h1>
                  </div>

                  {subTitle && (
                    <div className="c-hero-teaser__subtitle">
                      <p>{subTitle}</p>
                    </div>
                  )}
                </header>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: getKeycloakIsLogged(state),
    keycloakInitialized: getKeycloakInitialized(state),
  };
};
export default connect<{}, {}, PageHeaderProps>(mapStateToProps)(PageHeader);
