import { FormState } from "../models/FormState";
import { SAVE_FORM_DATA, RESET_FORM_DATA, FormActions, SAVE_LEGITIMATION_DATA } from "./actions/form.actions";

export function saveFormData(data: any): FormActions {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  };
}

export function resetFormData(): FormActions {
  return {
    type: RESET_FORM_DATA,
  };
}

export function getFormData(store: any) {
  return store.form.data;
}

export function saveLegitimationData(data: any): FormActions {
  return {
    type: SAVE_LEGITIMATION_DATA,
    payload: data,
  };
}

export function getLegitimationData(store: any) {
  return store.form.legitimationData;
}

const initialState: FormState = {
  data: {},
  legitimationData: {
    customerData: {},
    legitimationData: {},
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        data: {},
      };

    case SAVE_FORM_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case SAVE_LEGITIMATION_DATA:
      return {
        ...state,
        legitimationData: action.payload,
      };

    default:
      return state;
  }
}
