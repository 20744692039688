import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const DEFAULT_DATE = "__.__.____";
export const DATE_PLACEHOLDER = "TT.MM.JJJJ";

const parseDate = (dateString?: string) => dayjs(dateString, DATE_FORMAT, true);

export const isDateAvailable = (dateString?: string) => (dateString ?? "").trim().length > 0 && dateString !== DEFAULT_DATE;

export const isValidDate = (dateString?: string) => parseDate(dateString).isValid();

export const isPastDate = (dateString?: string) => {
  const date = parseDate(dateString);
  return date.isValid() && date.isBefore(dayjs(), "day");
};

export const isFutureDate = (dateString?: string) => {
  const date = parseDate(dateString);
  return date.isValid() && date.isAfter(dayjs(), "day");
};

export const isNotFutureDate = (dateString?: string) => {
  const date = parseDate(dateString);
  return date.isValid() && !date.isAfter(dayjs(), "day");
};

export const isOfLegalAge = (dateOfBirthString?: string) => {
  const dateOfBirth = parseDate(dateOfBirthString);
  const compareDate = dayjs().subtract(18, "year");
  return dateOfBirth.isValid() && dateOfBirth.isBefore(compareDate, "day");
};

export const isWithinPastYears = (years: number, includeDateInPast: boolean, dateString?: string) => {
  const date = parseDate(dateString);
  let dateInPast = dayjs().subtract(years, "year");
  if (includeDateInPast) {
    dateInPast = dateInPast.subtract(1, "day");
  }
  return date.isValid() && (date.isSame(dayjs(), "day") || date.isBefore(dayjs(), "day")) && date.isAfter(dateInPast, "day");
};

export const isEndDateNotBeforeBeginDate = (beginDateString?: string, endDateString?: string) => {
  const beginDate = parseDate(beginDateString);
  const endDate = parseDate(endDateString);
  return beginDate.isValid() && endDate.isValid() && !endDate.isBefore(beginDate, "day");
};

export const dateFormatter = (value?: unknown) => (typeof value === "string" ? dayjs(value).locale("de").format("DD.MM.YYYY") : "");
