import React from "react";
import { CommonPage } from "@components/common";
import { useProductContext } from "@product-pages/contexts";

interface Props {
  pageWrapSize?: PageWrapSize;
  children?: React.ReactNode;
}

export const CommonProductPage: React.FC<Props> = ({ children, pageWrapSize }) => {
  const { productConfig } = useProductContext();
  return (
    <CommonPage pageWrapSize={pageWrapSize} documentTitle={productConfig?.documentTitle} title={productConfig?.title}>
      {children}
    </CommonPage>
  );
};
