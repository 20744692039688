/* eslint-disable no-console */
/**
 * * EDDL VERSION
 *
 * This service is a bridge between the application and whatever analytics
 * software is used.
 *
 * By defining the REACT_APP_ANALYTICS_SCRIPT environment variable we are able
 * to either export the real or mocked implementation, so that from the
 * application point of view nothing changes and it can continue calling the
 * analytics logic as per usuall.
 */
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import merge from "lodash/merge";
import get from "lodash/get";

const timeToLoad = 6000;

const mock = {
  pageName: "",

  trackedFirst: false,
  queuedList: [],
  index: 0,

  default: {},
  datalayer: {},
  currentData: {},
  pageBottom: function pageBottom() {},

  setDefaults: function setDefaults(obj) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    Object.assign(this.default, obj);
    this.setCommonValues();
  },

  getFromDatalayer: function getFromDatalayer(key) {
    return get(this.datalayer, key);
  },

  getFromCurrentData: function getFromDatalayer(key) {
    return get(this.currentData, key);
  },

  addToDatalayer: function addToDatalayer(additions) {
    Object.keys(additions).forEach((key) => {
      set(this.datalayer, key, additions[key]);
    });
  },

  setCommonValues: function setCommonValues() {
    this.addToDatalayer({
      ...(process.env.REACT_APP_WEBSITE_BRAND ? { [this.keys.brand()]: process.env.REACT_APP_WEBSITE_BRAND } : {}),
    });
  },

  addToTrackingData(additions) {
    const addedData = {};
    Object.keys(additions).forEach((key) => {
      set(addedData, key, additions[key]);
    });
    this.currentData = merge({}, this.currentData, addedData);
  },

  setTrackingData(additions) {
    const clonedDefaultDatalayer = cloneDeep(this.default);
    const trackingData = {};
    Object.keys(additions).forEach((key) => {
      set(trackingData, key, additions[key]);
    });
    this.currentData = merge({}, clonedDefaultDatalayer, this.datalayer, trackingData);
  },

  trackViewChange: function viewChange() {
    this.addToTrackingData({
      [this.keys.event()]: "viewchange",
    });
    this.track("viewChange");
  },

  addToQueuedList: function addToQueuedList() {
    this.queuedList.push(this.index);
    this.index += 1;
  },

  removeFromQueuedList: function removeFromQueuedList() {
    if (this.queuedList.length) {
      this.queuedList.shift();
      this.trackedFirst = true;
    }
  },

  delayTrack: function delayTrack(track) {
    this.addToQueuedList();
    setTimeout(() => {
      track();
      this.removeFromQueuedList();
    }, timeToLoad);
  },

  doTrack: function doTrack(data, action) {
    const trackJob = () => {
      this.setTrackingData(data);
      this.track(action);
    };
    if (this.trackedFirst) {
      if (this.queuedList.length) {
        this.delayTrack(trackJob);
      } else {
        trackJob();
      }
    } else {
      this.delayTrack(trackJob);
    }
  },

  trackPage: function page(data) {
    setTimeout(() => {
      const pageName = get(data, this.keys.pageName());
      if (pageName) {
        this.addToDatalayer({
          [this.keys.pageName()]: pageName,
        });
      }
      this.doTrack(data, "page");
    }, 1000);
  },

  trackInteraction: function interaction(data) {
    this.doTrack(data, "interaction");
  },

  trackDownload: function download(data) {
    this.doTrack(data, "download");
  },

  log: function log(action) {
    console.log(
      `%cTrack ${action}\n %cpageName: %c${get(this.currentData, this.keys.pageName())}\n %cviewChange: %c${get(this.currentData, this.keys.viewChange())}\n %cevent0.type: %c${get(
        this.currentData,
        this.keys.eventType(0)
      )}\n %cevent0.action: %c${get(this.currentData, this.keys.eventAction(0))}\n\n %cDatalayer:`,
      "color:blue;font-size:1rem;font-weight:bold",
      "color:blue;font-size:0.9rem",
      "color:black",
      "color:blue;font-size:0.9rem",
      "color:black",
      "color:blue;font-size:0.9rem",
      "color:black",
      "color:blue;font-size:0.9rem",
      "color:black",
      "color:blue;font-size:0.9rem",
      this.currentData
    );
  },

  track: function track(action) {
    if (typeof action !== "string") {
      console.warn("Legacy tracking code detected");
      return;
    }
    this.mapTrackingData(action);
    // eslint-disable-next-line no-underscore-dangle
    if (!implementation.isLive || window.__CJ_DEBUG_ANALYTICS) {
      this.log(action);
    }
    window.adobeDataLayer.push(this.currentData);
    this.currentData = {};
  },

  mapTrackingData: function mapTrackingData(action) {
    if (action) {
      this.addToTrackingData({
        [this.keys.event()]: action,
      });
    }
    if (this.getFromCurrentData(this.keys.pageName()) !== "Error page") {
      this.addToTrackingData(this.getErrorCleanData());
    }
  },

  errorBaseData: {
    401: {
      eventType: "401: Login",
      eventAction: "401: Unauthorized",
      errorMessage: "Unauthorized",
    },
    403: {
      eventType: "403: Access",
      eventAction: "403: Denied",
      errorMessage: "Access denied",
    },
    404: {
      eventType: "404: Error",
      eventAction: "404: Page not found",
      errorMessage: "Page not found",
    },
    500: {
      eventType: "API: API",
      eventAction: "API: Error",
      errorMessage: "API Error",
    },
  },

  getErrorCleanData: function getErrorCleanData() {
    return {
      [this.keys.errorCode()]: null,
      [this.keys.errorMessage()]: null,
      [this.keys.errorCausingURL()]: null,
    };
  },

  setErrorInfo: function setErrorInfo(url, errorCode, errorMessage) {
    this.addToDatalayer({
      [this.keys.errorCode()]: errorCode,
      [this.keys.errorMessage()]: this.errorBaseData[`${errorCode}`]?.errorMessage || errorMessage,
      [this.keys.errorCausingURL()]: url,
    });
  },

  restoreErrorInfo: function restoreErrorInfo() {
    this.addToDatalayer(this.getErrorCleanData());
  },

  keys: {
    brand: () => "core.attributes.brand",
    context: () => "core.attributes.context",
    formType: () => "form.type",
    formName: () => "form.name",
    errorFields: () => "form.errorFields",
    lastTouchedField: () => "form.lastTouchedField",
    pageName: () => "core.pageInfo.pageName",
    faqText: () => "core.attributes.faqText",
    loginStatus: () => "customerData.loginStatus",
    productVariants: () => "core.category.productVariants",
    viewChange: () => "core.attributes.viewChange",
    event: () => "event",
    eventType: (eventCount) => `eventInfo[${eventCount}].eventType`,
    eventAction: (eventCount) => `eventInfo[${eventCount}].eventAction`,
    linkInformation: (eventCount) => `eventInfo[${eventCount}].linkInformation`,
    errorCode: () => "error.errorCode",
    errorMessage: () => "error.errorMessage",
    errorCausingURL: () => "error.errorCausingURL",
    dealerDataCompanyId: () => "dealerData.companyId",
    dealerDataCompanyName: () => "dealerData.companyName",
    dealerDataAddressStreet: () => "dealerData.address.street",
    dealerDataZipCode: () => "dealerData.address.zipCode",
    dealerDataCity: () => "dealerData.address.city",
    dealerDataState: () => "dealerData.address.state",
    addons: (eventCount) => `product[0].attributes.addOns[${eventCount}].name`,
    loggedInUserGroup: () => "customerData.loggedInUserGroup",
    maturityLevel: () => "core.category.maturityLevel",
    processType: () => "core.category.processType",
    ccResult: () => "customerData.ccResult",
  },
};

const real = {
  ...mock,
  isLive: true,
  pageBottom: function pageBottom() {
    window._satellite && window._satellite.track && window._satellite.pageBottom(); // eslint-disable-line
  },
};

let implementation = mock; // eslint-disable-line

if (process.env.REACT_APP_ANALYTICS_SCRIPT) {
  implementation = real;
}

export { implementation as Analytics };
