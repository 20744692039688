import { TransactionState } from "../models/TransactionState";
import { SAVE_CREDITCHECK_STATUS, SAVE_ALTERNATIVE_CALCULATION, SAVE_TRANSACTION_ID, TransactionActions, SAVE_EXPIRY_DATE, SET_TOKEN, SET_SELF_CREATED } from "./actions/transaction.actions";

export function setTransactionId(data: string): TransactionActions {
  return {
    type: SAVE_TRANSACTION_ID,
    payload: data,
  };
}

export function getSelfCreated(store: any) {
  return store.transaction.data.selfCreated;
}

export function setSelfCreated(data: boolean): TransactionActions {
  return {
    type: SET_SELF_CREATED,
    payload: data,
  };
}

export function getTransactionId(store: any) {
  return store.transaction.data.id;
}

export function setToken(data: string): TransactionActions {
  return {
    type: SET_TOKEN,
    payload: data,
  };
}

export function getToken(store: any) {
  return store.transaction.data.token;
}

export function saveExpiryDate(data: string): TransactionActions {
  return {
    type: SAVE_EXPIRY_DATE,
    payload: data,
  };
}

export function getExpiryDate(store: any) {
  return store.transaction.data.expiryDate;
}

export function setCreditCheckStatus(data: any): TransactionActions {
  return {
    type: SAVE_CREDITCHECK_STATUS,
    payload: data,
  };
}

export function getCreditCheckStatus(store: any) {
  return store.transaction.data.creditCheck?.status;
}

export function setAlternativeCalculation(data: any): TransactionActions {
  return {
    type: SAVE_ALTERNATIVE_CALCULATION,
    payload: data,
  };
}

export function getAlternativeCalculation(store: any) {
  return store.transaction.data.creditCheck?.alternativeCalculation;
}

const initialState: TransactionState = {
  data: {
    id: "",
    selfCreated: false,
    token: "",
    expiryDate: "",
    creditCheck: {
      status: "",
      alternativeCalculation: null,
    },
    tanModalShown: false,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SAVE_TRANSACTION_ID:
      return {
        ...state,
        data: {
          ...state.data,
          id: action.payload,
        },
      };
    case SET_SELF_CREATED:
      return {
        ...state,
        data: {
          ...state.data,
          selfCreated: action.payload,
        },
      };
    case SET_TOKEN:
      return {
        ...state,
        data: {
          ...state.data,
          token: action.payload,
        },
      };
    case SAVE_EXPIRY_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          expiryDate: action.payload,
        },
      };
    case SAVE_CREDITCHECK_STATUS:
      return {
        ...state,
        data: {
          ...state.data,
          creditCheck: {
            ...state.data.creditCheck,
            status: action.payload,
          },
        },
      };
    case SAVE_ALTERNATIVE_CALCULATION:
      return {
        ...state,
        data: {
          ...state.data,
          creditCheck: {
            ...state.data.creditCheck,
            alternativeCalculation: action.payload,
          },
        },
      };

    default:
      return state;
  }
}
