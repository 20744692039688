import { Button, ButtonContainer, Paragraph, SectionHeading } from "@vwfs-bronson/bronson-react";
import { CommonProductPage } from "@product-pages/components/common";
import { useTranslation } from "react-i18next";

export const ExpiredLinkPage = () => {
  const { t } = useTranslation();
  const texts = t("expired:texts", { returnObjects: true }) as Array<string>;
  const redirect = () => {
    window.location.href = t("expired:link");
  };
  return (
    <CommonProductPage>
      <SectionHeading level="3" smallSpacing={true} className="u-mb-large">
        {t("expired:title")}
      </SectionHeading>
      {texts.map((text: string) => (
        <Paragraph>{text}</Paragraph>
      ))}
      <ButtonContainer center className="u-mt-xlarge">
        <Button inContainer onClick={redirect}>
          {t("expired:button")}
        </Button>
      </ButtonContainer>
    </CommonProductPage>
  );
};
