import { KeycloakState } from "../models/KeycloakState";
import { KeycloakActions, SET_KEYCLOAK_DATA, SET_KEYCLOAK_IDP, SET_KEYCLOAK_IDPHINT, SET_KEYCLOAK_INITIALIZED, SET_KEYCLOAK_IS_LOGGED } from "./actions/keycloak.actions";

export const setKeycloakData = (data: any): KeycloakActions => {
  return {
    type: SET_KEYCLOAK_DATA,
    payload: data,
  };
};

export const getKeycloakData = (store: any) => {
  return store.keycloak?.data;
};

export const setKeycloakIdp = (data: any): KeycloakActions => {
  return {
    type: SET_KEYCLOAK_IDP,
    payload: data,
  };
};

export const getKeycloakIdp = (store: any) => {
  return store.keycloak?.idp;
};

export const setKeycloakIdpHint = (data: any): KeycloakActions => {
  return {
    type: SET_KEYCLOAK_IDPHINT,
    payload: data,
  };
};

export const getKeycloakIdpHint = (store: any) => {
  return store.keycloak?.idpHint;
};

export const setKeycloakIsLogged = (data: any): KeycloakActions => {
  return {
    type: SET_KEYCLOAK_IS_LOGGED,
    payload: data,
  };
};

export const getKeycloakIsLogged = (store: any) => {
  return store.keycloak?.isLogged;
};

export const setKeycloakInitialized = (data: any): KeycloakActions => {
  return {
    type: SET_KEYCLOAK_INITIALIZED,
    payload: data,
  };
};

export const getKeycloakInitialized = (store: any) => {
  return store.keycloak?.isLogged;
};

const initialState: KeycloakState = {
  data: {},
  idp: "",
  idpHint: "",
  isLogged: false,
  initialized: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_KEYCLOAK_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case SET_KEYCLOAK_IDP:
      return {
        ...state,
        idp: action.payload,
      };

    case SET_KEYCLOAK_IDPHINT:
      return {
        ...state,
        idpHint: action.payload,
      };

    case SET_KEYCLOAK_IS_LOGGED:
      return {
        ...state,
        isLogged: action.payload,
      };

    case SET_KEYCLOAK_INITIALIZED:
      return {
        ...state,
        initialized: action.payload,
      };

    default:
      return state;
  }
}
