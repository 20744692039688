import classNames from "classnames";
import { PropsWithChildren } from "react";

interface Props {
  className?: string;
}

export const FormSectionGroup = ({ className, children }: PropsWithChildren<Props>) => {
  return <div className={classNames("c-form-section-group", className)}>{children}</div>;
};
