import { StoreFrontState } from "../models/StoreFrontState";
import { StoreFrontActions, FETCH_STOREFRONT_PENDING, FETCH_STOREFRONT_DONE, FETCH_STOREFRONT_ERROR, FETCH_STOREFRONT_STORE_ID } from "./actions/storefront.actions";

export function pending(): StoreFrontActions {
  return {
    type: FETCH_STOREFRONT_PENDING,
    payload: {},
  };
}

export function initial(data: any): StoreFrontActions {
  return done(data);
}

export function done(data: any): StoreFrontActions {
  return {
    type: FETCH_STOREFRONT_DONE,
    payload: {
      data,
    },
  };
}

export function error(err: any): StoreFrontActions {
  return {
    type: FETCH_STOREFRONT_ERROR,
    payload: {
      err,
    },
  };
}

export function storeId(id: string): StoreFrontActions {
  return {
    type: FETCH_STOREFRONT_STORE_ID,
    payload: {
      id,
    },
  };
}

export function getStorefrontData(store: any) {
  return store.storefront.data;
}

export function getStoreFrontId(store: any) {
  return store.storefront.id;
}

const initialState: StoreFrontState = {
  id: "",
  pending: false,
  data: {},
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case FETCH_STOREFRONT_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      };

    case FETCH_STOREFRONT_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      };
    case FETCH_STOREFRONT_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      };
    case FETCH_STOREFRONT_STORE_ID:
      return {
        ...state,
        id: action.payload.id,
      };

    default:
      return state;
  }
}
