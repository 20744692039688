export enum JourneyRoutes {
  PREFILL = "prefill",
  CALCULATION = "calculation",
  CREDIT_CHECK = "credit-check",
  CREDIT_CHECK_SUCCESS = "credit-check-success",
  CREDIT_CHECK_REFUSED = "credit-check-refused",
  CREDIT_CHECK_MANUAL = "credit-check-manual",
  CREDIT_CHECK_ALTERNATIVE_OFFER = "credit-check-alternative-offer",
  POST_IDENT = "post-ident",
  DOCUMENT_UPLOAD = "document-upload",
  DOCUMENT_UPLOAD_SUCCESS = "document-upload-success",
  DOCUMENT_REUPLOAD_SUCCESS = "document-reupload-success",
  LEGITIMATION_SUCCESS = "legitimation-success",
  LEGITIMATION_ERROR = "legitimation-error",
  TEMPORARY_ERROR = "temporary-error",
  LEGITIMATION_MISMATCH = "legitimation-mismatch",
  SIGNATURE_FAILED = "signature-failed",
  SIGNATURE_SUCCESS = "signature-success",
  CONTRACT_CONFIRMATION = "contract-confirmation",
}

export enum JourneyStatus {
  INITIALIZED = "INITIALIZED",
  CALCULATED = "CALCULATED",
  APPLICATIONFORM_STARTED = "APPLICATIONFORM_STARTED",
  APPLICATIONFORM_COMPLETED = "APPLICATIONFORM_COMPLETED",
  CREDIT_APPROVED = "CREDIT_APPROVED",
  CREDIT_ALTERNATIVE_OFFER = "CREDIT_ALTERNATIVE_OFFER",
  CREDIT_CUSTOMER_ACTION_REQUIRED = "CREDIT_CUSTOMER_ACTION_REQUIRED",
  CREDIT_TO_BE_CONFIRMED = "CREDIT_TO_BE_CONFIRMED",
  CUSTOMER_UPLOAD = "CUSTOMER_UPLOAD",
  DOCUMENTS_UPLOAD = "DOCUMENTS_UPLOAD",
  DOCUMENTS_REUPLOAD = "DOCUMENTS_REUPLOAD",
  DOCUMENTS_UPLOADED = "DOCUMENTS_UPLOADED",
  DOCUMENTS_REUPLOADED = "DOCUMENTS_REUPLOADED",
  LEGITIMATION_REFUSED = "LEGITIMATION_REFUSED",
  LEGITIMATION_SUCCESSFUL_DATA_INCONSISTENT = "LEGITIMATION_SUCCESSFUL_DATA_INCONSISTENT",
  LEGITIMATION_SUCCESSFUL_DATA_MATCHED = "LEGITIMATION_SUCCESSFUL_DATA_MATCHED",
  CONTRACT_INTERRUPTED = "CONTRACT_INTERRUPTED",
  CONTRACT_PENDING = "CONTRACT_PENDING",
  CONTRACT_SIGNED = "CONTRACT_SIGNED",
  CONTRACT_PRINTED = "CONTRACT_PRINTED",
  CANCELED = "CANCELED",
}

export enum MismatchProcessStatus {
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR",
  SUCCESS_APPROVED = "SUCCESS_APPROVED",
  SUCCESS_REFUSED = "SUCCESS_REFUSED",
  SUCCESS_MANUAL = "SUCCESS_MANUAL",
  SUCCESS_ALTERNATIVE_OFFER = "SUCCESS_ALTERNATIVE_OFFER",
}

export enum ProductType {
  directcredit = "directcredit",
  directautocredit = "directautocredit",
}

export enum DocumentsGenerationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export enum Role {
  PRIVATE = "PRIVATE",
  VWGROUP_STAFF = "VWGROUP_STAFF",
}

export enum AdditionalProductType {
  KSB = "CREDIT_PROTECTION_INSURANCE",
  KSB_PLUS = "CPIPREMIUM",
}

export enum OriginSystem {
  VWWA = "VWWA",
}
