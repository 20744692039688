import { Reducer, useReducer } from "react";

export interface APIState<T> {
  isInitialized: boolean;
  isLoading: boolean;
  didSucceed: boolean;
  didFail: boolean;
  data?: T;
  error?: string;
}

type APIAction<T> =
  | {
      type: "reset";
    }
  | { type: "request"; keepData?: boolean }
  | { type: "success"; payload?: T }
  | { type: "failure"; error: string };

const apiReducer = <T>(previousState: APIState<T>, action: APIAction<T>): APIState<T> => {
  switch (action.type) {
    case "reset":
      return {
        isInitialized: false,
        isLoading: false,
        didSucceed: false,
        didFail: false,
      };
    case "request":
      return {
        isInitialized: true,
        isLoading: true,
        didSucceed: false,
        didFail: false,
        data: action.keepData ? previousState.data : undefined,
      };
    case "success":
      return {
        isInitialized: true,
        isLoading: false,
        didSucceed: true,
        didFail: false,
        data: action.payload,
      };
    case "failure":
      return {
        isInitialized: true,
        isLoading: false,
        didSucceed: false,
        didFail: true,
        error: action.error,
      };
    default:
      return previousState;
  }
};

export const useAPIReducer = <T>(initialData?: T) =>
  useReducer<Reducer<APIState<T>, APIAction<T>>>(
    apiReducer,
    initialData
      ? {
          isInitialized: true,
          isLoading: false,
          didSucceed: true,
          didFail: false,
          data: initialData,
        }
      : {
          isInitialized: false,
          isLoading: false,
          didSucceed: false,
          didFail: false,
        }
  );

export { FES_API } from "./fes-api";
