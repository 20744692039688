import { CalculationDetails } from "@products/necst-ratenkredit/apis/webcalc/types";
import { AdditionalProductType, JourneyRoutes, OriginSystem, ProductType } from "./models/enums";

export const getParam = (document: any, name: string) => new URL(document.location).searchParams.get(name);

export const redirectTo = (url: string) => (window.location.href = url);

export const openTabUrl = (url: string) => window.open(url, "_blank");

export const normalizeDate = (value: string) => {
  if (!value) return "";
  // it is already in the proper format
  if (value.indexOf(".") !== -1) return value;

  return value.split("-").reverse().join(".");
};

export const isEmpty = (value: any) => {
  return value === null || value === undefined;
};

export const isBooleanValue = (value: boolean) => {
  return value === true || value === false;
};

export const isDev = () => ["dev", "int", "cons"].includes(process.env.REACT_APP_STAGE || "");

export const buildPathWithParams = (path: string) => {
  return { pathname: path };
};

export const toBoolean = (v: any) => {
  if (typeof v === "string" || v instanceof String) {
    return v === "true";
  }
  return !!v;
};

export const isDirectAutoCredit = (product: string) => product === ProductType.directautocredit;

export const isKeycloakUrl = () => {
  const pagesWithKeycloak = [JourneyRoutes.PREFILL, JourneyRoutes.CALCULATION, JourneyRoutes.CREDIT_CHECK];
  return !!pagesWithKeycloak.find((page) => new URL(document.location as any).pathname?.includes(page));
};

export const getQuestionnaireUrl = (storefrontData: any) => storefrontData?.custom?.questionnaireUrl;

export const hasKSB = (calculation: CalculationDetails) => {
  return !!calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB);
};

export const getKSBProduct = (calculation: CalculationDetails) => calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB);

export const hasKSBPlus = (calculation: CalculationDetails) => {
  return !!calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB_PLUS);
};

export const getKSBPlusProduct = (calculation: CalculationDetails) =>
  calculation.additionalProducts?.find((additionalProdct: any) => additionalProdct.additionalProductType === AdditionalProductType.KSB_PLUS);

export const hasSomeKSB = (calculation: CalculationDetails) => hasKSB(calculation) || hasKSBPlus(calculation);

export const isVWWA = (originSystem: string) => originSystem === OriginSystem.VWWA;
