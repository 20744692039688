export const SET_FILES = "document//SET_FILES";
export const UPLOAD_DOCUMENT = "document//UPLOAD_DOCUMENT";
export const READ_DOCUMENT = "document//READ_DOCUMENT";
export const DELETE_DOCUMENT = "document//DELETE_DOCUMENT";
export const GET_DOCUMENTS = "document//GET_DOCUMENTS";

interface SetFilesAction {
  type: typeof SET_FILES;
  payload: any;
}
interface UploadDocumentAction {
  type: typeof UPLOAD_DOCUMENT;
  payload: any;
}

interface DeleteDocumentAction {
  type: typeof DELETE_DOCUMENT;
  payload: any;
}

interface ReadDocumentAction {
  type: typeof READ_DOCUMENT;
  payload: any;
}

interface getDocumentsAction {
  type: typeof GET_DOCUMENTS;
  payload: any;
}

export type FormActions = SetFilesAction | DeleteDocumentAction | UploadDocumentAction | ReadDocumentAction | getDocumentsAction;
