import { StandalonePage } from "@product-pages/common/types";
import { StandalonePagePath } from "./standalone-page-path";
import { ErrorPage } from "./ErrorPage";
import { LoadingPage } from "./LoadingPage";
import ErrorOptionPage from "./ErrorOptionPage";
import { ErrorDocumentUploadPage } from "./ErrorDocumentUploadPage";
import { ExpiredLinkPage } from "./ExpiredLinkPage";
import { ErrorCustomerService } from "./ErrorCustomerService";

export const DEFAULT_STANDALONE_PAGES: StandalonePage[] = [
  {
    path: StandalonePagePath.error,
    component: ErrorPage,
  },
  {
    path: StandalonePagePath.errorOption,
    component: ErrorOptionPage,
  },
  {
    path: StandalonePagePath.expiredLink,
    component: ExpiredLinkPage,
  },
  {
    path: StandalonePagePath.loading,
    component: LoadingPage,
  },
  {
    path: StandalonePagePath.errorDocument,
    component: ErrorDocumentUploadPage,
  },
  {
    path: StandalonePagePath.errorCustomerService,
    component: ErrorCustomerService,
  },
];
