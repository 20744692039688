import { sleep } from "./sleep";

export const importRetry = async (importFn: () => Promise<any>, retries = 2, interval = 1000): Promise<any> => {
  try {
    return importFn();
  } catch (error) {
    if (retries) {
      await sleep(interval);
      return importRetry(importFn, retries - 1, interval);
    } else {
      throw error;
    }
  }
};
