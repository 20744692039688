import { FeatureConfig, ProductConfig } from "@product-pages/common/types";
import { CommonProductFeatures } from "@product-pages/features";
import React, { Reducer, useCallback, useReducer } from "react";

interface ProductContextState {
  featureConfig: FeatureConfig;
}

type ProductContextAction = { type: "setFeatureConfig"; payload: FeatureConfig };

const productContextReducer = (previousState: ProductContextState, action: ProductContextAction): ProductContextState => {
  switch (action.type) {
    case "setFeatureConfig": {
      return { ...previousState, featureConfig: action.payload };
    }
    default:
      return previousState;
  }
};

interface ProductContextProps {
  productConfig: ProductConfig;
}

const useProductContextInternal = (props: ProductContextProps) => {
  const [state, dispatch] = useReducer<Reducer<ProductContextState, ProductContextAction>>(productContextReducer, {
    featureConfig: CommonProductFeatures.getDefaultFeatureConfig(),
  });

  const setFeatureConfig = useCallback((featureConfig: FeatureConfig) => {
    dispatch({ type: "setFeatureConfig", payload: featureConfig });
  }, []);

  return { ...props, ...state, setFeatureConfig };
};

interface ProductContextProps {
  children?: React.ReactNode;
}

const ProductContext = React.createContext<ReturnType<typeof useProductContextInternal>>({} as ReturnType<typeof useProductContextInternal>);

export const ProductContextProvider: React.FC<ProductContextProps> = ({ children, ...props }: ProductContextProps) => (
  <ProductContext.Provider value={useProductContextInternal(props)}>{children}</ProductContext.Provider>
);

export const useProductContext = () => React.useContext(ProductContext);
