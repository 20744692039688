import { Analytics } from ".";
import { DEFAULT_DATALAYER } from "./../../datalayer";

export const initializeAnalytics = () => {
  Analytics.setDefaults(DEFAULT_DATALAYER);
  setBreakPoint();
};

export const setBreakPoint = () => {
  let breakpoint = "xxl";
  if (window.matchMedia("(max-width: 480px)").matches) {
    breakpoint = "xs";
  } else if (window.matchMedia("(max-width: 720px)").matches) {
    breakpoint = "s";
  } else if (window.matchMedia("(max-width: 960px)").matches) {
    breakpoint = "m";
  } else if (window.matchMedia("(max-width: 1280px)").matches) {
    breakpoint = "l";
  } else if (window.matchMedia("(max-width: 1600px)").matches) {
    breakpoint = "xl";
  }
  Analytics.addToDatalayer({
    "design.browserResolutionBreakpoint": breakpoint,
  });
};
