import { useKeycloak } from "@react-keycloak/web";

const useKeycloakConditonal = (shouldUseKeycloak: boolean) => {
  if (shouldUseKeycloak) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { keycloak, initialized } = useKeycloak();
    return { keycloak, initialized };
  }

  return { keycloak: undefined, initialized: false };
};

export default useKeycloakConditonal;
