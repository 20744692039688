import { DescriptionList } from "@vwfs-bronson/bronson-react";

interface Props {
  termText: React.ReactNode;
  children?: React.ReactNode;
}

export const DescriptionListItem: React.FC<Props> = ({ termText, children }) => (
  <DescriptionList.Group termText={termText}>
    <DescriptionList.Detail>{children}</DescriptionList.Detail>
  </DescriptionList.Group>
);
