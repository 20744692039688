import { CustomState } from "../models/CustomState";
import { CustomActions, SET_HASDACERROR, SET_SHOW_CONTINUELATER, SET_LEGITIMATION_URL, SET_SHOW_EMAIL_VALIDATION, SET_IS_EMAIL_VALIDATED, SET_IS_TLS_SUPPORTED } from "./actions/custom.actions";

export function setShowContinueLater(data: any): CustomActions {
  return {
    type: SET_SHOW_CONTINUELATER,
    payload: data,
  };
}

export function getShowContinueLater(store: any) {
  return store.custom.data.showContinueLater;
}

export function setHasDacError(data: any): CustomActions {
  return {
    type: SET_HASDACERROR,
    payload: data,
  };
}

export function getHasDacError(store: any) {
  return store.custom.data.hasDacError;
}

export function setLegitimationUrl(data: any): CustomActions {
  return {
    type: SET_LEGITIMATION_URL,
    payload: data,
  };
}

export function getLegitimationUrl(store: any) {
  return store.custom.data.legitimationUrl;
}

export function getShowEmailValidation(store: any) {
  return store.custom.data.showEmailValidationModal;
}

export function setShowEmailvalidation(data: any): CustomActions {
  return {
    type: SET_SHOW_EMAIL_VALIDATION,
    payload: data,
  };
}

export function getIsEmailValidated(store: any) {
  return store.custom.data.isEmailValidated;
}

export function setIsEmailValidated(data: any): CustomActions {
  return {
    type: SET_SHOW_EMAIL_VALIDATION,
    payload: data,
  };
}

export function getIsTLSSupported(store: any) {
  return store.custom.data.isTLSSupported;
}

export function setIsTlsSupported(data: any): CustomActions {
  return {
    type: SET_IS_TLS_SUPPORTED,
    payload: data,
  };
}

const initialState: CustomState = {
  data: {
    showContinueLater: false,
    hasDacError: false,
    legitimationUrl: undefined,
    showEmailValidationModal: false,
    isEmailValidated: false,
    isTLSSupported: false,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_SHOW_CONTINUELATER:
      return {
        ...state,
        data: {
          ...state.data,
          showContinueLater: action.payload,
        },
      };
    case SET_HASDACERROR:
      return {
        ...state,
        data: {
          ...state.data,
          hasDacError: action.payload,
        },
      };
    case SET_LEGITIMATION_URL:
      return {
        ...state,
        data: {
          ...state.data,
          legitimationUrl: action.payload,
        },
      };
    case SET_SHOW_EMAIL_VALIDATION:
      return {
        ...state,
        data: {
          ...state.data,
          showEmailValidationModal: action.payload,
        },
      };
    case SET_IS_EMAIL_VALIDATED:
      return {
        ...state,
        data: {
          ...state.data,
          isEmailValidated: action.payload,
        },
      };
    case SET_IS_TLS_SUPPORTED:
      return {
        ...state,
        data: {
          ...state.data,
          isTLSSupported: action.payload,
        },
      };
    default:
      return state;
  }
}
