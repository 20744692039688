export const RESET_FORM_DATA = "form//RESET_FORM_DATA";
export const SAVE_FORM_DATA = "form//SAVE_FORM_DATA";
export const SAVE_LEGITIMATION_DATA = "form//SAVE_LEGITIMATION_DATA";

interface SaveFormDataAction {
  type: typeof SAVE_FORM_DATA;
  payload: any;
}

interface ResetFormDataAction {
  type: typeof RESET_FORM_DATA;
}

interface SaveLegitimationDataAction {
  type: typeof SAVE_LEGITIMATION_DATA;
  payload: any;
}

export type FormActions = SaveFormDataAction | ResetFormDataAction | SaveLegitimationDataAction;
