import { scrollToTop } from "@common/scroll";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ScrollToTopProps = {
  children?: React.ReactNode;
};

export const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }: ScrollToTopProps) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname, search]);

  return <>{children}</>;
};
