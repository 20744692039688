import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18n as i18nDefault } from "../../resources/base/content";
import { continueLaterLocalization } from "@vwfs-its/sf-sac-frontend";

const localization = continueLaterLocalization({
  locale: "de",
  brandName: "vw",
  dataProtectionLink: "https://www.vwfs.it/privacypolicies.html",
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: i18nDefault,
    },
    lng: "de",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

i18n.addResourceBundle("de", "sf-sac", localization, true, true);
