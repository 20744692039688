import { FES_API } from "@apis/common";
import { ContactDataRequest } from "@common/models/contact-data";
import { EmploymentDataRequest } from "@common/models/employment-data";
import { FinancialDataRequest } from "@common/models/financial-data";
import { PaymentDataRequest } from "@common/models/payment-data";
import { PersonalDataRequest } from "@common/models/personal-data";
import { store } from "@services/redux";
import * as actions from "@services/redux/features/transaction.redux";
import { CalculationRequest } from "products/necst-ratenkredit/apis/webcalc/types";

export const applicationStart = () => {
  return FES_API.post(`/application-start`, {}).then((response) => {
    store.dispatch(actions.setToken(response?.data?.token));
    store.dispatch(actions.setTransactionId(response?.data?.id));
    return response.data;
  });
};

export const applicationCalculation = (data: CalculationRequest) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.post(`/application/${id}/calculation`, data).then((response) => {
    return response.data;
  });
};

export const formStart = () => {
  const id = store.getState().transaction?.data.id;
  return FES_API.post(`/application/${id}/form-start`, {}).then((response) => {
    return response?.data;
  });
};

export const savePersonalData = (data: PersonalDataRequest) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.patch(`/application/${id}/personal-data`, data).then((response) => {
    return response.data;
  });
};

export const saveContactData = (data: ContactDataRequest) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.put(`/application/${id}/contact-data`, data).then((response) => {
    return response.data;
  });
};

export const saveEmploymentData = (data: EmploymentDataRequest) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.patch(`/application/${id}/employment-data`, data).then((response) => {
    return response.data;
  });
};

export const saveFinancialData = (data: FinancialDataRequest) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.put(`/application/${id}/financial-data`, data).then((response) => {
    return response.data;
  });
};

export const savePaymentData = (data: PaymentDataRequest) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.patch(`/application/${id}/payment-data`, data).then((response) => {
    return response.data;
  });
};

export const getDocuments = () => {
  const id = store.getState().transaction?.data.id;
  return FES_API.post(`/application/${id}/documents-online`).then((response) => {
    return response.data;
  });
};

export const getDocumentsStatus = () => {
  const id = store.getState().transaction?.data.id;
  return FES_API.get(`/application/${id}/documents/status`).then((response) => {
    return response.data;
  });
};

export const sendContinueLaterData = (data: any) => {
  const id = store.getState().transaction?.data.id;
  return FES_API.post(`/application/${id}/save`, data).then((response) => {
    return response.data;
  });
};
