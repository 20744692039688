import dayjs from "dayjs";
import { FeatureConfig, MaintenanceConfig } from "@product-pages/common/types";
import { ENV } from "@common/env";
import { DATE_TIME_FORMAT } from "@common/date";

export class CommonProductFeatures {
  static getDefaultFeatureConfig(): FeatureConfig {
    return {
      isValidationEnabled: false,
    };
  }

  static isMaintenanceActive(maintenance?: MaintenanceConfig) {
    if (!maintenance) {
      return false;
    }
    const start = dayjs(maintenance.start, DATE_TIME_FORMAT);
    const end = dayjs(maintenance.end, DATE_TIME_FORMAT);
    const now = dayjs();
    return (now.isSame(start, "minute") || now.isAfter(start, "minute")) && (now.isSame(end, "minute") || now.isBefore(end, "minute")) && (maintenance.stages as string[]).includes(ENV.stage);
  }
}
