export const SET_KEYCLOAK_DATA = "keycloak//SET_KEYCLOAK_DATA";
export const SET_KEYCLOAK_IDP = "keycloak//SET_KEYCLOAK_IDP";
export const SET_KEYCLOAK_IDPHINT = "keycloak//SET_KEYCLOAK_IDPHINT";
export const SET_KEYCLOAK_IS_LOGGED = "keycloak//SET_KEYCLOAK_ISL_OGGED";
export const SET_KEYCLOAK_INITIALIZED = "keycloak//SET_KEYCLOAK_INITIALIZED";

interface SetKeycloakData {
  type: typeof SET_KEYCLOAK_DATA;
  payload: any;
}

interface SetKeycloakIdp {
  type: typeof SET_KEYCLOAK_IDP;
  payload: any;
}
interface SetKeycloakIdpHint {
  type: typeof SET_KEYCLOAK_IDPHINT;
  payload: any;
}

interface SetKeycloakIsLogged {
  type: typeof SET_KEYCLOAK_IS_LOGGED;
  payload: any;
}

interface SetKeycloakInitialized {
  type: typeof SET_KEYCLOAK_INITIALIZED;
  payload: any;
}

export type KeycloakActions = SetKeycloakData | SetKeycloakIdp | SetKeycloakIdpHint | SetKeycloakIsLogged | SetKeycloakInitialized;
