export const SAVE_TRANSACTION_ID = "transaction//SAVE_TRANSACTION_ID";
export const SET_SELF_CREATED = "transaction//SET_SELF_CREATED";
export const SET_TOKEN = "transaction//SET_TOKEN";
export const SAVE_EXPIRY_DATE = "transaction//SAVE_EXPIRY_DATE";
export const SAVE_CREDITCHECK_STATUS = "transaction//SAVE_CREDITCHECK_STATUS";
export const SAVE_ALTERNATIVE_CALCULATION = "transaction//SAVE_ALTERNATIVE_CALCULATION";
export const SET_TAN_MODAL_SHOWN = "transaction//SET_TAN_MODAL_SHOWN";

interface SaveTransactionId {
  type: typeof SAVE_TRANSACTION_ID;
  payload: any;
}

interface SetSelfCreated {
  type: typeof SET_SELF_CREATED;
  payload: any;
}

interface SetToken {
  type: typeof SET_TOKEN;
  payload: any;
}

interface SaveExpiryDate {
  type: typeof SAVE_EXPIRY_DATE;
  payload: any;
}

interface SaveCreditCheckStatus {
  type: typeof SAVE_CREDITCHECK_STATUS;
  payload: any;
}

interface SaveAlternativeCalculation {
  type: typeof SAVE_ALTERNATIVE_CALCULATION;
  payload: any;
}

export type TransactionActions = SaveTransactionId | SetSelfCreated | SaveExpiryDate | SaveCreditCheckStatus | SaveAlternativeCalculation | SetToken;
